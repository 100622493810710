import axios from '@axios'
import store from '@/store'

export default {
  async postPreguntasCategoriaData(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/trivia/getPreguntasCategoriaWeb`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idCategoria: id,
      },
    })
    return response
  },
  async postPreguntaCategoriaInsert(token, formulario) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/trivia/insertPreguntaCategoriaWeb`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        categoria: formulario.categoria,
        pregunta: formulario.pregunta,
        a: formulario.a,
        b: formulario.b,
        c: formulario.c,
        d: formulario.d,
        respuesta: formulario.respuesta,
        puntos: formulario.puntos,
        multiplicador: formulario.multiplicador,
        tiempo: formulario.tiempo,
        inicio: formulario.inicio,
        fin: formulario.fin,
        estado: formulario.estado,
      },
    })
    return response
  },
  async postPreguntaCategoriaUpdate(token, formulario) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/trivia/updatePreguntaCategoriaWeb`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: formulario.id,
        categoria: formulario.categoria,
        pregunta: formulario.pregunta,
        a: formulario.a,
        b: formulario.b,
        c: formulario.c,
        d: formulario.d,
        respuesta: formulario.respuesta,
        puntos: formulario.puntos,
        multiplicador: formulario.multiplicador,
        tiempo: formulario.tiempo,
        inicio: formulario.inicio,
        fin: formulario.fin,
        estado: formulario.estado,
      },
    })
    return response
  },
  async postPreguntaCategoriaDelete(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/trivia/deletePreguntaCategoriaWeb`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idPregunta: id,
      },
    })
    return response
  },
  async postCategoriaData(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/trivia/getCategoriasWeb`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idUsuario: id,
      },
    })
    return response
  },
  async postCategoriaInsert(token, formulario) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/trivia/insertCategoriaWeb`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        empresa: formulario.empresa,
        area: formulario.area,
        campana: formulario.campana,
        tipo: formulario.tipoUsuario,
        nombre: formulario.nombre,
        descripcion: formulario.descripcion,
        estado: formulario.estado,
        clase: formulario.clase,
      },
    })
    return response
  },
  async postCategoriaUpdate(token, formulario) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/trivia/updateCategoriaWeb`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: formulario.id,
        empresa: formulario.empresa,
        area: formulario.area,
        campana: formulario.campana,
        tipo: formulario.tipoUsuario,
        nombre: formulario.nombre,
        descripcion: formulario.descripcion,
        estado: formulario.estado,
        clase: formulario.clase,
      },
    })
    return response
  },
  async postCategoriaDelete(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/trivia/deleteCategoriaWeb`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idCategoria: id,
      },
    })
    return response
  },
}
