<template>
  <div>
    <div class="d-block d-md-none mb-2">
      <b-button
        v-show="$can('triviaCrear', 'triviaCrear')"
        variant="primary"
        block
        @click="modalCategoria(0)"
      >
        <feather-icon
          icon="SaveIcon"
          class="mr-50"
        />
        <span>Agregar categoria</span>
      </b-button>
    </div>
    <div class="d-none d-md-flex align-items-center justify-content-end mb-2">
      <b-button
        v-show="$can('triviaCrear', 'triviaCrear')"
        class="mr-50"
        variant="primary"
        @click="modalCategoria(0)"
      >
        <feather-icon
          icon="SaveIcon"
          class="mr-50"
        />
        <span>Agregar trivia</span>
      </b-button>
    </div>
    <div class="d-flex justify-content-between flex-wrap my-2">
      <!-- filter -->
      <b-form-group
        label="Buscador"
        label-cols-sm="3"
        label-align-sm="left"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="buscadorCategoria"
            placeholder="Buscador"
            type="search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!buscadorCategoria"
              @click="buscadorCategoria = ''"
            >
              Limpiar
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </div>
    <b-row>
      <b-col cols="12">
        <b-card title="Lista de trivias">
          <b-row>
            <b-table
              responsive
              striped
              hover
              show-empty
              bordered
              sticky-header
              :small="true"
              :items="itemsCategoria"
              :fields="fieldsCategoria"
              :filter="buscadorCategoria"
              :filter-included-fields="filterOnCategoria"
            >
              <template #empty>
                <h3 class="text-center my-2">
                  No tiene información para mostrar
                </h3>
              </template>
              <template #emptyfiltered>
                <h3 class="text-center my-2">
                  No se encontro ningun resultado
                </h3>
              </template>
              <template #head()="scope">
                <div class="text-nowrap">
                  {{ scope.label }}
                </div>
              </template>
              <template #cell()="data">
                <span class="text-nowrap">{{ data.value }}</span>
              </template>
              <template v-slot:cell(estado)="row">
                <span v-if="row.item.estado === '0'">Inactivo</span>
                <span v-else>Activo</span>
              </template>
              <template #cell(acciones)="row">
                <b-button
                  v-show="$can('triviaVer', 'triviaVer')"
                  :id="`modalDetalleCategoria${row.item.id}`"
                  variant="gradient-primary"
                  class="btn-icon mr-50"
                  size="sm"
                  @click="modalDetalleCategoria(row.item.id)"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
                <b-button
                  v-show="$can('triviaActualizar', 'triviaActualizar')"
                  :id="`modalCategoria${row.item.id}`"
                  variant="gradient-success"
                  class="btn-icon mr-50"
                  size="sm"
                  @click="modalCategoria(row.item.id)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  v-show="$can('triviaEliminar', 'triviaEliminar')"
                  :id="`eliminarCategoria${row.item.id}`"
                  variant="gradient-danger"
                  class="btn-icon"
                  size="sm"
                  @click="eliminarCategoria(row.item.id)"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
                <b-tooltip
                  :target="`modalDetalleCategoria${row.item.id}`"
                  title="Ver preguntas"
                  placement="bottom"
                />
                <b-tooltip
                  :target="`modalCategoria${row.item.id}`"
                  title="Editar categoria"
                  placement="bottom"
                />
                <b-tooltip
                  :target="`eliminarCategoria${row.item.id}`"
                  title="Eliminar categoria"
                  placement="bottom"
                />
              </template>
            </b-table>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      v-model="popupAgregarCategoria"
      hide-footer
      scrollable
      no-close-on-backdrop
      :size="'lg'"
      :title="tituloCategoria"
      @hidde="limpiarCategoria()"
    >
      <b-row>
        <b-col>
          <div class="d-flex align-items-center justify-content-end mb-2">
            <b-button
              variant="primary"
              class="ml-1"
              @click="registrarCategoria()"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50 text-white"
              />
              <span class="text-white">Registrar</span>
            </b-button>
            <!--
            <b-button
              variant="danger"
              class="ml-1"
              @click="limpiarCategoria()"
            >
              <feather-icon
                icon="FileIcon"
                class="mr-50"
              />
              Limpiar
            </b-button>
            -->
          </div>
        </b-col>
      </b-row>
      <p class="text-right">
        <small class="text-muted">Los campos marcados con * son obligatorios</small>
      </p>
      <b-row>
        <b-col
          cols="12"
        >
          <validation-observer ref="filterCategoria">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label-for="empresa"
                  label="Empresa"
                >
                  <b-form-select
                    id="empresa"
                    ref="empresa"
                    v-model="formCategoria.empresa"
                    :options="optionsEmpresa"
                    @input="obtenerAreaCategoria"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label-for="area"
                  label="Area"
                >
                  <b-form-select
                    id="area"
                    ref="area"
                    v-model="formCategoria.area"
                    :options="optionsArea"
                    @input="obtenerCampanaCategoria"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  label-for="campana"
                  label="Campaña"
                >
                  <b-form-select
                    id="campana"
                    ref="campana"
                    v-model="formCategoria.campana"
                    :options="optionsCampana"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label-for="tipo"
                  label="Tipo usuario *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="tipo usuario"
                    rules="required"
                  >
                    <b-form-select
                      id="tipo"
                      ref="tipo"
                      v-model="formCategoria.tipoUsuario"
                      :options="optionsTipoUsuario"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="nombre"
                  label="Nombre *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="nombre"
                    rules="required"
                  >
                    <b-form-input
                      id="nombre"
                      ref="nombre"
                      v-model="formCategoria.nombre"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar nombre"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="descripcion"
                  label="Descripcion *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="descripcion"
                    rules="required"
                  >
                    <b-form-input
                      id="descripcion"
                      ref="descripcion"
                      v-model="formCategoria.descripcion"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar descripcion"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label-for="estado"
                  label="Estado *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="estado"
                    rules="required"
                  >
                    <b-form-select
                      id="estado"
                      ref="estado"
                      v-model="formCategoria.estado"
                      :options="optionsEstado"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      v-model="popupDetalleCategoria"
      hide-footer
      scrollable
      no-close-on-backdrop
      :size="'xl'"
      title="Detalle categoria"
    >
      <div class="d-block d-md-none mb-2">
        <b-button
          v-show="$can('triviaCrear', 'triviaCrear')"
          variant="primary"
          block
          @click="modalPreguntaCategoria(0)"
        >
          <feather-icon
            icon="SaveIcon"
            class="mr-50"
          />
          <span>Agregar pregunta</span>
        </b-button>
      </div>
      <div class="d-none d-md-flex align-items-center justify-content-end mb-2">
        <b-button
          v-show="$can('triviaCrear', 'triviaCrear')"
          class="mr-50"
          variant="primary"
          @click="modalPreguntaCategoria(0)"
        >
          <feather-icon
            icon="SaveIcon"
            class="mr-50"
          />
          <span>Agregar pregunta</span>
        </b-button>
      </div>
      <div class="d-flex justify-content-between flex-wrap my-2">
        <!-- filter -->
        <b-form-group
          label="Buscador"
          label-cols-sm="3"
          label-align-sm="left"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="buscadorDetalleCategoria"
              placeholder="Buscador"
              type="search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!buscadorDetalleCategoria"
                @click="buscadorDetalleCategoria = ''"
              >
                Limpiar
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <b-row>
        <b-col cols="12">
          <b-card title="Lista de preguntas">
            <b-row>
              <b-table
                responsive
                striped
                hover
                show-empty
                bordered
                sticky-header
                :small="true"
                :items="itemsDetalleCategoria"
                :fields="fieldsDetalleCategoria"
                :filter="buscadorDetalleCategoria"
                :filter-included-fields="filterOnDetalleCategoria"
              >
                <template #empty>
                  <h3 class="text-center my-2">
                    No tiene información para mostrar
                  </h3>
                </template>
                <template #emptyfiltered>
                  <h3 class="text-center my-2">
                    No se encontro ningun resultado
                  </h3>
                </template>
                <template #head()="scope">
                  <div class="text-nowrap">
                    {{ scope.label }}
                  </div>
                </template>
                <template #cell()="data">
                  <span class="text-nowrap">{{ data.value }}</span>
                </template>
                <template v-slot:cell(estado)="row">
                  <span v-if="row.item.estado === '0'">Inactivo</span>
                  <span v-else>Activo</span>
                </template>
                <template #cell(acciones)="row">
                  <b-button
                    v-show="$can('triviaActualizar', 'triviaActualizar')"
                    :id="`modalDetalleCategoria${row.item.id}`"
                    variant="gradient-success"
                    class="btn-icon mr-50"
                    size="sm"
                    @click="modalPreguntaCategoria(row.item.id)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button
                    v-show="$can('triviaEliminar', 'triviaEliminar')"
                    :id="`eliminarDetalleCategoria${row.item.id}`"
                    variant="gradient-danger"
                    class="btn-icon"
                    size="sm"
                    @click="eliminarPreguntaCategoria(row.item.id)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                  <b-tooltip
                    :target="`modalPreguntaCategoria${row.item.id}`"
                    title="Editar pregunta"
                    placement="bottom"
                  />
                  <b-tooltip
                    :target="`eliminarPreguntaCategoria${row.item.id}`"
                    title="Eliminar pregunta"
                    placement="bottom"
                  />
                </template>
              </b-table>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      v-model="popupPreguntaCategoria"
      hide-footer
      scrollable
      no-close-on-backdrop
      :size="'lg'"
      :title="tituloPregunta"
      @hidde="limpiarPreguntaCategoria()"
    >
      <b-row>
        <b-col>
          <div class="d-flex align-items-center justify-content-end mb-2">
            <b-button
              variant="primary"
              class="ml-1"
              @click="registrarPreguntaCategoria()"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50 text-white"
              />
              <span class="text-white">Registrar</span>
            </b-button>
            <b-button
              variant="danger"
              class="ml-1"
              @click="limpiarPreguntaCategoria()"
            >
              <feather-icon
                icon="FileIcon"
                class="mr-50"
              />
              Limpiar
            </b-button>
          </div>
        </b-col>
      </b-row>
      <p class="text-right">
        <small class="text-muted">Los campos marcados con * son obligatorios</small>
      </p>
      <b-row>
        <b-col
          cols="12"
        >
          <validation-observer ref="filterPreguntaCategoria">
            <b-row>
              <b-col>
                <b-form-group
                  label-for="pregunta"
                  label="Pregunta *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="nombre"
                    rules="required"
                  >
                    <b-form-input
                      id="pregunta"
                      ref="pregunta"
                      v-model="formPreguntaCategoria.pregunta"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar nombre"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="a"
                  label="Respuesta A *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="respuesta a"
                    rules="required"
                  >
                    <b-form-input
                      id="a"
                      ref="a"
                      v-model="formPreguntaCategoria.a"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar respuesta"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="b"
                  label="Respuesta B *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="respuesta b"
                    rules="required"
                  >
                    <b-form-input
                      id="b"
                      ref="b"
                      v-model="formPreguntaCategoria.b"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar respuesta"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="c"
                  label="Respuesta C *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="respuesta c"
                    rules="required"
                  >
                    <b-form-input
                      id="c"
                      ref="c"
                      v-model="formPreguntaCategoria.c"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar respuesta"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="d"
                  label="Respuesta D *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="respuesta d"
                    rules="required"
                  >
                    <b-form-input
                      id="d"
                      ref="d"
                      v-model="formPreguntaCategoria.d"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar respuesta"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label-for="respuesta"
                  label="Respuesta *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="respuesta"
                    rules="required"
                  >
                    <b-form-select
                      id="respuesta"
                      ref="respuesta"
                      v-model="formPreguntaCategoria.respuesta"
                      :options="optionsRespuesta"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label-for="puntos"
                  label="Puntos *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="puntos"
                    rules="required"
                  >
                    <b-form-input
                      id="d"
                      ref="d"
                      v-model="formPreguntaCategoria.puntos"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar puntos"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label-for="multiplicador"
                  label="Multiplicador *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="multiplicador"
                    rules="required"
                  >
                    <b-form-input
                      id="multiplicador"
                      ref="multiplicador"
                      v-model="formPreguntaCategoria.multiplicador"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar multiplicador"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label-for="tiempo"
                  label="Tiempo *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="tiempo"
                    rules="required"
                  >
                    <b-form-input
                      id="tiempo"
                      ref="tiempo"
                      v-model="formPreguntaCategoria.tiempo"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar tiempo"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  label-for="inicio"
                  label="Fecha inicio (dd/mm/aaaa)"
                >

                  <b-form-datepicker
                    id="inicio"
                    ref="inicio"
                    v-model="formPreguntaCategoria.inicio"
                    placeholder="Ingresar fecha"
                    locale="es"
                    :date-format-options="{ year: 'numeric' }"
                  />

                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label-for="fin"
                  label="Fecha fin (dd/mm/aaaa)"
                >
                  <b-form-datepicker
                    id="fin"
                    ref="fin"
                    v-model="formPreguntaCategoria.fin"
                    placeholder="Ingresar fecha"
                    locale="es"
                    :date-format-options="{ year: 'numeric' }"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label-for="estado"
                  label="Estado *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="estado"
                    rules="required"
                  >
                    <b-form-select
                      id="estado"
                      ref="estado"
                      v-model="formPreguntaCategoria.estado"
                      :options="optionsEstado"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BButton,
  BInputGroup,
  BTable,
  BInputGroupAppend,
  BFormInput,
  BTooltip,
  BFormSelect,
  BFormDatepicker,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
// eslint-disable-next-line import/no-cycle
import empresaService from '@/service/empresaService'
import areaService from '@/service/areaService'
import campanaService from '@/service/campanaService'
import usuarioService from '@/service/usuarioService'
import triviaService from '@/service/triviaService'
import productoService from '@/service/productoService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BButton,
    BInputGroup,
    BTable,
    BInputGroupAppend,
    BFormInput,
    BTooltip,
    BFormSelect,
    BFormDatepicker,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      token: localStorage.getItem('token'),
      required,
      optionsEmpresa: [{ value: null, text: 'Seleccionar empresa' }],
      optionsArea: [{ value: null, text: 'Seleccionar area' }],
      optionsCampana: [{ value: null, text: 'Seleccionar campaña' }],
      optionsTipoUsuario: [{ value: null, text: 'Seleccionar tipo' }],
      optionsEstado: [
        { value: null, text: 'Seleccionar estado' },
        { value: '1', text: 'Activo' },
        { value: '0', text: 'Inactivo' },
      ],
      optionsRespuesta: [
        { value: null, text: 'Respuesta' },
        { value: 'a', text: 'Respuesta A' },
        { value: 'b', text: 'Respuesta B' },
        { value: 'c', text: 'Respuesta C' },
        { value: 'd', text: 'Respuesta D' },
      ],
      optionsFrecuencia: [],
      optionsTipoCompra: [],
      buscadorCategoria: null,
      filterOnCategoria: ['empresa', 'area', 'campana', 'nombre'],
      fieldsCategoria: [
        {
          label: 'Código',
          key: 'id',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },

        {
          label: 'Empresa',
          key: 'empresa',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Area',
          key: 'area',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Campaña',
          key: 'campana',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Nombre',
          key: 'nombre',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Estado',
          key: 'estado',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Acciones',
          key: 'acciones',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      itemsCategoria: [],

      buscadorDetalleCategoria: null,
      filterOnDetalleCategoria: ['empresa', 'area', 'campana', 'nombre'],
      fieldsDetalleCategoria: [
        {
          label: 'Código',
          key: 'id',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },

        {
          label: 'Pregunta',
          key: 'pregunta',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Puntos',
          key: 'puntos',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Multiplicador',
          key: 'multiplicador',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Tiempo',
          key: 'tiempo',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Estado',
          key: 'estado',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Acciones',
          key: 'acciones',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      itemsDetalleCategoria: [],

      popupAgregarCategoria: false,
      popupDetalleCategoria: false,
      popupPreguntaCategoria: false,
      tituloCategoria: '',
      formCategoria: {
        id: 0,
        empresa: null,
        area: null,
        campana: null,
        tipoUsuario: null,
        nombre: '',
        descripcion: '',
        estado: '1',
        clase: null,
      },
      tituloPregunta: '',
      formPreguntaCategoria: {
        id: 0,
        categoria: '0',
        pregunta: '',
        a: '',
        b: '',
        c: '',
        d: '',
        respuesta: null,
        puntos: '',
        multiplicador: '1',
        tiempo: '20',
        estado: '1',
        inicio: null,
        fin: null,
      },
    }
  },
  mounted() {
    this.$nextTick(async () => {
      await this.obtenerTipoUsuario()
      await this.obtenerEmpresa()
      await this.obtenerCategoria()
      await this.obtenerFrecuencia()
      await this.obtenerTipo()
      this.$store.commit('app/UPDATE_ISLOADING', false)
    })
  },
  methods: {
    async obtenerTipo() {
      this.optionsTipoCompra = [{ value: null, text: 'Seleccionar tipo' }]
      const response = await productoService.postProductoTipoData(
        this.token,
      )
      if (response.data.data.length > 0) {
        response.data.data.forEach(element => this.optionsTipoCompra.push({
          value: element.key,
          text: element.value,
          carpeta: element.carpeta,
          categoria: element.categoria,
        }))
      }
    },
    async obtenerFrecuencia() {
      this.optionsFrecuencia = []
      const response = await productoService.postClaseProductoData(
        this.token,
      )
      if (response.data.data.length > 0) {
        response.data.data.forEach(element => this.optionsFrecuencia.push({
          value: element.value,
          text: element.text,
        }))
      }
    },
    async obtenerEmpresa() {
      this.optionsEmpresa = [{ value: null, text: 'Seleccionar empresa' }]
      const responseEmpresa = await empresaService.postEmpresaUsuarioData(this.token, this.userData.id)
      if (responseEmpresa.data.data.length > 0) {
        responseEmpresa.data.data.forEach(element => this.optionsEmpresa.push({
          value: element.id,
          text: element.name,
        }))
      }
    },
    async obtenerAreaCategoria() {
      this.$store.commit('app/UPDATE_MESSAGE', 'Obteniendo información.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      this.optionsArea = [{ value: null, text: 'Seleccionar area' }]
      this.optionsCampana = [{ value: null, text: 'Seleccionar campaña' }]
      this.formCategoria.area = null
      this.formCategoria.campana = null
      if (this.formCategoria.empresa != null) {
        const responseArea = await areaService.postAreaData(
          this.token,
          this.formCategoria.empresa,
        )
        if (responseArea.data.data.length > 0) {
          responseArea.data.data.forEach(element => this.optionsArea.push({
            value: element.id,
            text: element.name,
          }))
        }
      }
      setTimeout(() => {
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }, 1000)
    },
    async obtenerCampanaCategoria() {
      this.$store.commit('app/UPDATE_MESSAGE', 'Obteniendo información.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      this.optionsCampana = [{ value: null, text: 'Seleccionar campaña' }]
      this.formCategoria.campana = null
      if (this.formCategoria.area != null) {
        const responseCampana = await campanaService.postCampanaData(
          this.token,
          this.formCategoria.area,
        )
        if (responseCampana.data.data.length > 0) {
          responseCampana.data.data.forEach(element => this.optionsCampana.push({
            value: element.id,
            text: element.name,
          }))
        }
      }
      setTimeout(() => {
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }, 1000)
    },
    async obtenerTipoUsuario() {
      this.optionsTipoUsuario = [{ value: null, text: 'Seleccionar tipo' }]
      const response = await usuarioService.postTipoUsuarioData(
        this.token,
      )
      if (response.data.data.length > 0) {
        response.data.data.forEach(element => this.optionsTipoUsuario.push({
          value: element.tipoUsuario,
          text: element.tipoUsuario,
        }))
      }
    },
    async obtenerCategoria() {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const responseTrivia = await triviaService.postCategoriaData(this.token, this.userData.id)
      if (responseTrivia.data.data.length > 0) {
        this.itemsCategoria = responseTrivia.data.data
      }
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async obtenerPreguntasCategoria(id) {
      this.itemsDetalleCategoria = []
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const responseTrivia = await triviaService.postPreguntasCategoriaData(this.token, id)
      this.formPreguntaCategoria.categoria = id
      if (responseTrivia.data.data.length > 0) {
        this.itemsDetalleCategoria = responseTrivia.data.data
      }
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async modalCategoria(id) {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      if (id === 0) {
        this.tituloCategoria = 'Agregar trivia'
        this.formCategoria = {
          id: 0,
          empresa: null,
          area: null,
          campana: null,
          tipoUsuario: null,
          nombre: '',
          descripcion: '',
          estado: '1',
          clase: null,
        }
      } else {
        this.tituloProducto = 'Modificar trivia'
        const data = this.itemsCategoria.find(element => element.id === id)
        this.formCategoria.empresa = (data.empresa === '') ? null : data.id_empresa
        await this.obtenerAreaCategoria()
        this.formCategoria.area = (data.id_area === '') ? null : data.id_area
        await this.obtenerCampanaCategoria()
        this.formCategoria.campana = (data.id_campana === '') ? null : data.id_campana
        this.formCategoria.id = data.id
        this.formCategoria.nombre = data.nombre
        this.formCategoria.descripcion = data.descripcion
        this.formCategoria.estado = data.estado
        this.formCategoria.tipoUsuario = (data.tipo === '') ? null : data.tipo
        this.formCategoria.clase = (data.clase === '') ? null : data.clase
      }
      this.popupAgregarCategoria = true
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async limpiarCategoria() {
      this.$refs.filterCategoria.reset()
      this.formCategoria = {
        id: 0,
        empresa: null,
        area: null,
        campana: null,
        tipoUsuario: null,
        nombre: '',
        descripcion: '',
        estado: '1',
        clase: null,
      }
    },
    async eliminarCategoria(id) {
      this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      try {
        await triviaService.postCategoriaDelete(this.token, id)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Categoria eliminada con éxito.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
        setTimeout(() => {
          this.obtenerCategoria()
        }, 500)
      } catch (error) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Ocurrio un error, por favor intentar nuevamente.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
      } finally {
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }
    },
    async registrarCategoria() {
      const success = await this.$refs.filterCategoria.validate()
      if (success) {
        this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
        this.$store.commit('app/UPDATE_ISLOADING', true)
        try {
          if (this.formCategoria.id === 0) {
            await triviaService.postCategoriaInsert(this.token, this.formCategoria)
          } else {
            await triviaService.postCategoriaUpdate(this.token, this.formCategoria)
          }
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Notificación',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'Registro satisfactorio.',
              },
            },
            {
              position: 'bottom-center',
              closeOnClick: true,
            },
          )
          this.popupAgregarCategoria = false
          setTimeout(() => {
            this.obtenerCategoria()
          }, 1000)
        } catch (error) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Notificación',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Ocurrio un error, por favor intentar nuevamente.',
              },
            },
            {
              position: 'bottom-center',
              closeOnClick: true,
            },
          )
        } finally {
          this.$store.commit('app/UPDATE_ISLOADING', false)
        }
      }
    },
    async modalDetalleCategoria(id) {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      this.popupDetalleCategoria = true
      await this.obtenerPreguntasCategoria(id)
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async modalPreguntaCategoria(id) {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      if (id === 0) {
        this.tituloPregunta = 'Agregar pregunta'
        this.formPreguntaCategoria.id = 0
        this.formPreguntaCategoria.pregunta = ''
        this.formPreguntaCategoria.a = ''
        this.formPreguntaCategoria.b = ''
        this.formPreguntaCategoria.c = ''
        this.formPreguntaCategoria.d = ''
        this.formPreguntaCategoria.respuesta = null
        this.formPreguntaCategoria.puntos = ''
        this.formPreguntaCategoria.multiplicador = '1'
        this.formPreguntaCategoria.tiempo = '20'
        this.formPreguntaCategoria.estado = '1'
        this.formPreguntaCategoria.inicio = null
        this.formPreguntaCategoria.fin = null
      } else {
        this.tituloPregunta = 'Modificar pregunta'
        const data = this.itemsDetalleCategoria.find(element => element.id === id)
        this.formPreguntaCategoria.id = data.id
        this.formPreguntaCategoria.categoria = data.categoria
        this.formPreguntaCategoria.pregunta = data.pregunta
        this.formPreguntaCategoria.a = data.a
        this.formPreguntaCategoria.b = data.b
        this.formPreguntaCategoria.c = data.c
        this.formPreguntaCategoria.d = data.d
        this.formPreguntaCategoria.respuesta = data.respuesta
        this.formPreguntaCategoria.puntos = data.puntos
        this.formPreguntaCategoria.multiplicador = data.multiplicador
        this.formPreguntaCategoria.tiempo = data.tiempo
        this.formPreguntaCategoria.estado = data.estado
        this.formPreguntaCategoria.inicio = data.inicio
        this.formPreguntaCategoria.fin = data.fin
      }
      this.popupPreguntaCategoria = true
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async eliminarPreguntaCategoria(id) {
      this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      try {
        await triviaService.postPreguntaCategoriaDelete(this.token, id)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Pregunta eliminada con éxito.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
        setTimeout(() => {
          this.modalDetalleCategoria(this.formPreguntaCategoria.categoria)
        }, 500)
      } catch (error) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Ocurrio un error, por favor intentar nuevamente.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
      } finally {
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }
    },
    async registrarPreguntaCategoria() {
      const success = await this.$refs.filterPreguntaCategoria.validate()
      if (success) {
        this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
        this.$store.commit('app/UPDATE_ISLOADING', true)
        try {
          console.log(this.formPreguntaCategoria)
          if (this.formPreguntaCategoria.id === 0) {
            await triviaService.postPreguntaCategoriaInsert(this.token, this.formPreguntaCategoria)
          } else {
            await triviaService.postPreguntaCategoriaUpdate(this.token, this.formPreguntaCategoria)
          }
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Notificación',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'Registro satisfactorio.',
              },
            },
            {
              position: 'bottom-center',
              closeOnClick: true,
            },
          )
          this.popupAgregarCategoria = false
          setTimeout(() => {
            this.popupPreguntaCategoria = false
            this.modalDetalleCategoria(this.formPreguntaCategoria.categoria)
          }, 1000)
        } catch (error) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Notificación',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Ocurrio un error, por favor intentar nuevamente.',
              },
            },
            {
              position: 'bottom-center',
              closeOnClick: true,
            },
          )
        } finally {
          this.$store.commit('app/UPDATE_ISLOADING', false)
        }
      }
    },
    async limpiarPreguntaCategoria() {
      this.$refs.filterPreguntaCategoria.reset()
      this.formPreguntaCategoria.id = 0
      this.formPreguntaCategoria.pregunta = ''
      this.formPreguntaCategoria.a = ''
      this.formPreguntaCategoria.b = ''
      this.formPreguntaCategoria.c = ''
      this.formPreguntaCategoria.d = ''
      this.formPreguntaCategoria.respuesta = null
      this.formPreguntaCategoria.puntos = ''
      this.formPreguntaCategoria.multiplicador = '1'
      this.formPreguntaCategoria.tiempo = '20'
      this.formPreguntaCategoria.estado = '1'
      this.formPreguntaCategoria.inicio = null
      this.formPreguntaCategoria.fin = null
    },
  },
}
</script>

  <style>

  </style>
